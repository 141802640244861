<template>
  <div class="relative h-full">
    <!-- eslint-disable max-len -->
    <sidenav />
    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <topbar />
      <header class="border-b-2 border-gray-200 bg-white">
        <div class="flex px-4 lg:px-6 pt-3 pb-5">
          <div class="flex flex-grow items-center">
            <div class="flex-shrink-0">
              <img class="w-btn mr-3" src="brand-icon-key.svg" />
            </div>
            <h1 class="font-bold text-xl">API</h1>
          </div>
        </div>
      </header>
      <main class="px-4 lg:px-6 pb-5 flex-grow">
        <div class="flex-grow">
          <div class="flex py-5 mb-4">
            <div class="flex flex-grow items-center">
              <div class="flex-shrink-0">
                <img class="w-10.5 rounded-lg shadow-md" srcset="thumb-vtex@2x.png 2x" src="thumb-vtex.png" alt="Logo VTEX"/>
              </div>
              <div class="ml-4">
                <h4 class="font-semibold leading-5">Integração VTEX</h4>
              </div>
            </div>
            <div>
              <a href="https://docs.parcelex.com.br" target="_blank" class="button button-primary-subtle">
                <span>Documentação</span>
                <ExternalLinkIcon size="24"/>
              </a>
            </div>
          </div>
          <div>
            <div>
              <h3 class="font-bold text-lg mb-3">Produção</h3>
              <div class="border divide-y divide-gray-200 rounded-md">
                <div class="lg:grid lg:grid-cols-12 lg:items-center">
                  <div class="col-span-3 px-4 pt-4 lg:pt-0">
                    <span class="font-semibold">Application Key</span>
                  </div>
                  <div class="col-span-9 p-4">
                    <div class="bg-gray-100 rounded-md flex justify-between">
                      <div class="break-all py-1.5 px-3">601ae818cd689ee24ca7d288</div>
                      <div class="text-gray-500 pr-1">
                        <button class="button-sm button-icon hover:text-primary-500">
                          <DuplicateIcon size="24"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lg:grid lg:grid-cols-12 lg:items-center">
                  <div class="col-span-3 px-4 pt-4 lg:pt-0">
                    <span class="font-semibold">Application Token</span>
                  </div>
                  <div class="col-span-9 p-4">
                    <div class="bg-gray-100 rounded-md flex justify-between">
                      <div class="break-all py-1.5 px-3">•••••••••••••••••••••••••</div>
                      <div class="text-gray-500 flex space-x-1 pr-1">
                        <button class="button-sm button-icon hover:text-primary-500">
                          <EyeIcon size="24"/>
                        </button>
                        <button class="button-sm button-icon hover:text-primary-500">
                          <DuplicateIcon size="24"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-9">
              <h3 class="font-bold text-lg mb-3">Homologação</h3>
              <div class="border divide-y divide-gray-200 rounded-md">
                <div class="lg:grid lg:grid-cols-12 lg:items-center">
                  <div class="col-span-3 px-4 pt-4 lg:pt-0">
                    <span class="font-semibold">Application Key</span>
                  </div>
                  <div class="col-span-9 p-4">
                    <div class="bg-gray-100 rounded-md flex justify-between">
                      <div class="break-all py-1.5 px-3 ">601ae818cd689ee24ca7d288</div>
                      <div class="text-gray-500 pr-1">
                        <button class="button-sm button-icon hover:text-primary-500">
                          <DuplicateIcon size="24"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lg:grid lg:grid-cols-12 lg:items-center">
                  <div class="col-span-3 px-4 pt-4 lg:pt-0">
                    <span class="font-semibold">Application Token</span>
                  </div>
                  <div class="col-span-9 p-4">
                    <div class="bg-gray-100 rounded-md flex justify-between">
                      <div class="break-all py-1.5 px-3">e5bbz2qu168000d4bbz2qu168000d4</div>
                      <div class="text-gray-500 flex space-x-1 pr-1">
                        <button class="button-sm button-icon hover:text-primary-500">
                          <EyeOffIcon size="24"/>
                        </button>
                        <button class="button-sm button-icon hover:text-primary-500">
                          <DuplicateIcon size="24"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ExternalLinkIcon, DuplicateIcon, EyeIcon, EyeOffIcon } from '@vue-hero-icons/outline';
import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';

export default {
  name: 'api',
  metaInfo: {
    title: 'API',
  },
  components: {
    ExternalLinkIcon,
    DuplicateIcon,
    EyeOffIcon,
    EyeIcon,
    Sidenav,
    Topbar,
  },
  data: () => ({
    openModal: false,
  }),
  methods: {
    showModal() {
      this.openModal = !this.openModal;
    },
  },
  mounted() {},
};
</script>
